import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../../components/layout/layout";
import Seo from "../../components/layout/seo";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Top from "../../components/blog/top";
import Bio from "../../components/blog/bio";
import Cat from "../../components/blog/category";
import SubscriptionBanner from "../../components/blog/banner";
import { Box, Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import logo from "/src/images/iso_color.png";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#202026",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const BlogIndex = ({ data, location }) => {
  const [visible, setVisible] = useState(3);
  const [enabled, setEnabled] = useState(true);

  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Blog",

    crumbSeparator: " > ",
    crumbStyle: { color: "#666" },
    crumbActiveStyle: { color: "orange" },
  });

  const showMoreItems = () => {
    if (data.allMarkdownRemark.totalCount > visible) {
      setVisible((prevValue) => prevValue + 3);
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  };

  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;
  const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMarkdownRemark.nodes;

  if (posts.length === 0) {
    return (
      <ThemeProvider theme={cs_theme}>
        <Layout location={location} title={siteTitle}>
          <Seo title="MedTech Blog | Custom Surgical Blog" />

          <p>
            No blog posts found. Add markdown posts to "content/blog" (or the
            directory you specified for the "gatsby-source-filesystem" plugin in
            gatsby-config.js).
          </p>
        </Layout>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={cs_theme}>
      <Layout location={location} title={siteTitle}>
        <Seo title="MedTech Blog | Custom Surgical Blog" />
        <Bio />
        <div className="viewed">
          <div>Most viewed</div>
          <hr className="line"></hr>
        </div>
        <Top />
        <Cat />

        <div className="viewed">
          <div>Recent Articles</div>
          <hr style={{ margin: 0 }}></hr>
        </div>

        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            justifyContent={{ xs: "center", sm: "flex-start" }}
          >
            {posts.slice(0, visible).map((post) => {
              const title = post.frontmatter.title || post.fields.slug;

              return (
                <Grid item xs={10} md={4} sm={6} key={post.fields.slug}>
                  <article
                    className="post-list-item"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <header>
                      <Link to={post.fields.slug} itemProp="url">
                        {" "}
                        <GatsbyImage
                          image={getImage(post.frontmatter.image)}
                          key=""
                          alt={title}
                          imgStyle={{
                            borderRadius: "5px",
                            boxShadow: "1px 1px 1px 2px rgba(0, 0, 0, 0.05)",
                          }}
                          style={{
                            borderRadius: "5px",
                            boxShadow: " 1px 1px 1px 2px rgba(0, 0, 0, 0.05)",
                            height: "270px",
                          }}
                        />
                      </Link>
                      <div className="photo_text_flex">
                        <div className="tags_photo" style={{ color: "black" }}>
                          {post.frontmatter.tags.map((tag, i) => [
                            <div
                              key={i}
                              className={
                                tag === "News"
                                  ? "tags-news"
                                  : tag === "Technology"
                                  ? "tags-technology"
                                  : tag === "Stories"
                                  ? "tags-stories"
                                  : tag === "How to"
                                  ? "tags-how-to"
                                  : "tags-news"
                              }
                            >
                              {tag}
                              {i < post.frontmatter.tags.length - 1 ? ", " : ""}
                            </div>,
                          ])}
                        </div>{" "}
                        <div className="timer">
                          <StaticImage
                            src="../../images/blog/timer.png"
                            alt="timer image"
                            style={{ height: "20px" }}
                          />
                          <div className="timeread">
                            &#160;{post.timeToRead} mins
                          </div>
                        </div>
                      </div>

                      <h1 className="h2_arc">
                        <Link
                          to={post.fields.slug}
                          itemProp="url"
                          className="link_arc"
                        >
                          {title}
                        </Link>
                      </h1>

                      <div></div>
                    </header>
                    <section>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: post.frontmatter.description,
                        }}
                        itemProp="description"
                      />
                    </section>
                  </article>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <div style={{ textAlign: "center" }}>
          {enabled ? (
            <button onClick={showMoreItems} className="loadmore">
              Load more
            </button>
          ) : (
            <button onClick={showMoreItems} className="loadmore_disabled">
              Load more
            </button>
          )}
        </div>
        <SubscriptionBanner postName={"index-page"} tag={""} />
      </Layout>
    </ThemeProvider>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/blog/" } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        timeToRead
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          views
          tags
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      totalCount
    }
  }
`;
